.footer {
  // position: fixed;
  bottom: 0;
  background-color: var(--dark-blue);
  color: #fff;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 6rem;
  p {
    color: #fff;
  }
}
