.contact {
  .card {
    padding: 2rem;
    // border: 1px solid #ccc;
    border-radius: 20px;
    background-color: #fff;
    label {
      color: #000;
    }
  }

  .card2 {
    padding: 7rem;
    // margin: 0 auto;
    // text-align: center;
    border-radius: 20px;
    background-color: #000;
    color: #fff;
    height: 80%;
    h3,
    p {
      color: #fff;
    }
    .icons {
      margin-top: 3rem;
      span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // margin-bottom: 1rem;
        a,
        p {
          margin-left: 20px;
        }
      }
    }
  }

  .section {
    display: flex;
    align-items: flex-start;

    form {
      width: 100%;
      max-width: 100%;
      margin-right: 1rem;
      margin-bottom: 1rem;

      label {
        display: block;
        font-size: 1.4rem;
        font-weight: 500;
      }
      input[type="text"],
      input[type="number"],
      input[type="file"],
      input[type="email"],
      select,
      textarea,
      input[type="password"] {
        background-color: #fff;
        display: block;
        font-size: 1.6rem;
        font-weight: 300;
        padding: 1rem;
        margin: 1rem auto;
        width: 100%;
        border: 1px solid #777;
        border-radius: 3px;
        outline: none;
        border-radius: 10px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .contact {
    .section {
      flex-direction: column;
    }
  }
}
