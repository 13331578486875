.filter {
  font-family: "Poppins";
  h4 {
    margin-top: 1rem;
  }
  .category button {
    display: block;
    text-align: left;
    width: 100%;
    height: 2.5rem;
    font-size: 1.2em;
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-bottom: 5px;
    border-bottom: 2px solid #777;
    padding: 1em 0;
  }

  .category button:hover {
    background-color: orangered;
  }
  .brand {
    select {
      font-size: 1.4rem;
      font-weight: 400;
      padding: .3em;

      width: 80%;
      border: 1px solid #777;
      border-radius: 3px;
      outline: none;
    }
  }
}

@media screen and (max-width: 700px) {
  .filter {
    .category button {
      width: 100%;
      // margin: 1em 0;
    }
    .brand {
      select {
        width: 100%;
      }
    }
  }
}

.active {
  position: relative;
  margin-bottom: 2px;
  padding-left: 1rem;
}

.active::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 3px;
  height: 100%;
  background-color: var(--color-danger);
}
