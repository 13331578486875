.slider {
  width: 100%;
  height: 90vh;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // opacity: 1;
  transform: translateX(-50%);
  transition: all 1s ease;
  
}

@media screen and (min-width: 600px) {
  .slide img {
    width: 100%;
    height: 100%;
  }
}



.slide img {
  height: 100%;
}

.content {
  border: 2px solid white;
  position: absolute;
  border-radius: 16px;
  text-align: center;
  top: 16rem;
  left: 50%;
  opacity: 0;
  width: 35%;
  padding: 2rem;
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
  transform: translateX(-50%);
  background-color: gray;
  background: rgba(0, 0, 0, 0.95);
  animation: slide-up .5s ease .5s;
  animation-fill-mode: both;
  h3 {
    font-size: 4rem;
  }
}

@keyframes slide-up {
  0% {
    visibility: visible;
    top: 100rem;
  }
 
  100% {
    visibility: visible;
    top: 16rem;
  }
}

@media screen and (max-width: 600px) {
  .content {
    // top: 20rem;
    width: 80%;
    padding: 4rem;
    h3 {
      font-size: 2.5rem;
    }
  }
  @keyframes slide-up {
    0% {
      visibility: visible;
      top: 100rem;
    }
   
    100% {
      visibility: visible;
      top: 14rem;
    }
  }
  
}

.content > * {
  color: #fff;
  margin-bottom: 2.5rem;
}

.current {
  opacity: 1;
  transform: translateX(0);
}

.current .content {
  opacity: 1;
}
.arrow {
  border: 2px solid #ffffff;
  border-radius: 50%;
  background: #fff;
  color: #fff;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.arrow:hover {
  background: orangered;
  border: 2px solid #000;

}

.next {
  right: 1.5rem;
  color: #000;
}
.prev {
  left: 1.5rem;
  color: #000;
}

hr {
  height: 2px;
  background: #000;
  width: 50%;
}
